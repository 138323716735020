

$(document).ready(function() {

    $('.unmask').on('click', function(){
        var input = $(this).prev('input');
        if($(input).attr('type') == 'password'){
            $(this).removeClass('fa-eye-slash').addClass('fa-eye');
            changeType($(input), 'text');
        }else{
            $(this).removeClass('fa-eye').addClass('fa-eye-slash');
            changeType($(input), 'password');
        }
        return false;
    });

    $('#formulario-password input[type="button"]').on('click', function(e) {

        var f = $(this).closest('form');
        var pass1 = $(f).find('input[name="pass1"]');
        var pass2 = $(f).find('input[name="pass2"]');

        // Ocultamos mensajes de error
        $('#titulo-pagina .error').addClass('oculto');

        // Y quitamos error de los campos de form
        $(f).find('.error').removeClass('error');

        if( $(pass1).val() === '' ) {
            marcaCampoError($(pass1));
            return;
        }

        if( !checkPassword($(pass1).val()) ) {
            marcaCampoError($(pass1),'error_contra_forta');
            return;
        }

        if( $(pass2).val() === '' ) {
            marcaCampoError($(pass2));
            return;
        }

        if( $(pass1).val() !== $(pass2).val() ) {
            marcaCampoError($(pass2),'error_contra_coinc');
            return;
        }

        if ( $(f).find('.error').length == 0 ) {
            sendActualizaPass();
        } else {
            muestraMsgError();
        }
    });

});

function sendActualizaPass(){

    var f = $('#formulario-password');
    var ajax_data = $(f).serialize();
    $.ajax({
        url: DIR_APP + 'ajax/act-pass',
        data: ajax_data,
        type: 'post',
        dataType: 'json',
        success: function(json) {
            if ( json.cres === 0 ) {

                $(f)[0].reset();

                ponMensaje({'container_id':'ventana_ok'});

            } else if ( json.cres == -5 ) {

                marcaCampoError($(f).find('input[name="pass1"]'),'error_contra_forta');
                muestraMsgError();

            } else if ( json.cres == -10 ) {
                muestraMsgError();
            } else {
                muestraMsgError('error_server');
                console.log('No se ha podido actualiar la contraseña. Error = ' + json.cres);
            }
        },
        error:function (xhr, ajaxOptions, thrownError) {
            muestraMsgError('error_server');
            console.log('fail ajax',thrownError);
        }
    });
}

function checkPassword(pass) {

    if (pass.length < 8) {
        return false;
    }

    // Tiene que cumplir 3 medidas
    var numMedidasOK = 0;

    if ( pass.match(/[0-9]+/) ) {
        numMedidasOK++;
    }

    if ( pass.match(/[a-z]+/) ) {
        numMedidasOK++;
    }

    if ( pass.match(/[A-Z]+/) ) {
        numMedidasOK++;
    }

    if ( pass.match(/[^0-9a-zA-Z]+/) ) {
        numMedidasOK++;
    }

    return (numMedidasOK >= 3);
}

function changeType(x, type) {
    if(x.prop('type') == type)
        return x;
    try {
        return x.prop('type', type);
    } catch(e) {
        var html = $('<div>').append(x.clone()).html();
        var regex = /type=(\")?([^\"\s]+)(\")?/; //matches type=text or type="text"
        var tmp = $(html.match(regex) == null ?
            html.replace(">", ' type="' + type + '">') :
            html.replace(regex, 'type="' + type + '"') );
        tmp.data('type', x.data('type') );
        var events = x.data('events');
        var cb = function(events) {
            return function() {
                for(var i in events) {
                    var y = events[i];
                    for(var j in y){
                        tmp.bind(i, y[j].handler);
                    }
                }
            };
        }(events);
        x.replaceWith(tmp);
        setTimeout(cb, 10);
        return tmp;
    }
}
