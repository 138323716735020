$(document).ready(function() {

    $('#textoLibre').on('keypress',function(e) {
        if(e && e.keyCode == 13) {
            $(this).closest('form').submit();
        }
    });

    $('.buscador_cont img').on('click',function(){
        $(this).closest('form').submit();
    });

    $('a.sel-empleado').on('click',function(e) {
        e.preventDefault();

        if ( $('#select-empleados').closest('div[data-idp]').length ) {
            // El select está en una fila de documento, lo ocultamos y también su botón de descarga también
            $('#select-empleados').addClass('oculto');
            $('#select-empleados').closest('div[data-idp]').find('a.download').addClass('oculto');
            $('#select-empleados').closest('div[data-idp]').find('a.sel-empleado').removeClass('oculto');
        }

        // Movemos el select de empleados a la posición y hacemos para que pueda seleccionar el empleado y descargar
        $(this).addClass('oculto');
        $('#select-empleados').insertAfter($(this));
        $('#select-empleados').removeClass('oculto');

        // Y por último, mostramos el botón para descargar
        $(this).closest('div[data-idp]').find('a.download').removeClass('oculto');

    });

    $('a.sel-proveedor').on('click',function(e) {
        e.preventDefault();

        if ( $('#select-proveedores').closest('div[data-idp]').length ) {
            // El select está en una fila de documento, lo ocultamos y también su botón de descarga también
            $('#select-proveedores').addClass('oculto');
            $('#select-proveedores').closest('div[data-idp]').find('a.download').addClass('oculto');
            $('#select-proveedores').closest('div[data-idp]').find('a.sel-proveedor').removeClass('oculto');
        }

        // Movemos el select de proveedores a la posición y hacemos para que pueda seleccionar el proveedor y descargar
        $(this).addClass('oculto');
        $('#select-proveedores').insertAfter($(this));
        $('#select-proveedores').removeClass('oculto');

        // Y por último, mostramos el botón para descargar
        $(this).closest('div[data-idp]').find('a.download').removeClass('oculto');

    });

    $('a.download').on('click',function(e) {
        e.preventDefault();

        var urldown = $(this).attr('href');

        // miramos si es necesario seleccionar antes un empleado, proveedor...
        var perso_download = $(this).closest('div[data-idp]').find('a.perso-download');
        if ( $(perso_download).length ) {
            // Si es una descarga personalizada
            if ( $(perso_download).hasClass('sel-empleado') ) {
                // hay que tener un empleado
                var idEmp = $('#select-empleados select').val();

                if ( idEmp == '' ) {
                    ponMensaje({'mensaje':'Selecciona el empleado del que quieres el documento "'+$(this).closest('div[data-idp]').find('div.col').first().text()+'"'});
                    return;
                }

                urldown += '/' + idEmp;

            } else if ( $(perso_download).hasClass('sel-proveedor') ) {
                // hay que tener un proveedor
                var idProv = $('#select-proveedores select').val();

                if ( idProv == '' ) {
                    ponMensaje({'mensaje':'Selecciona el proveedor del que quieres el documento "'+$(this).closest('div[data-idp]').find('div.col').first().text()+'"'});
                    return;
                }

                urldown += '/' + idProv;
            }
        }
        window.open(urldown);
    });

});



$( function() {
    $.widget( "custom.combobox", {
      _create: function() {
        this.wrapper = $( "<span>" )
          .addClass( "custom-combobox" )
          .insertAfter( this.element );
 
        this.element.hide();
        this._createAutocomplete();
        this._createShowAllButton();
      },
 
      _createAutocomplete: function() {
        var selected = this.element.children( ":selected" ),
          value = selected.val() ? selected.text() : "";
 
        this.input = $( "<input>" )
          .appendTo( this.wrapper )
          .val( value )
          .attr( "title", "" )
          .addClass( "custom-combobox-input ui-widget ui-widget-content ui-state-default ui-corner-left" )
          .autocomplete({
            delay: 0,
            minLength: 0,
            source: $.proxy( this, "_source" )
          })
          .tooltip({
            classes: {
              "ui-tooltip": "ui-state-highlight"
            }
          });
 
        this._on( this.input, {
          autocompleteselect: function( event, ui ) {
            ui.item.option.selected = true;
            this._trigger( "select", event, {
              item: ui.item.option
            });
          },
 
          autocompletechange: "_removeIfInvalid"
        });
      },
 
      _createShowAllButton: function() {
        var input = this.input,
          wasOpen = false;
 
        $( "<a>" )
          .attr( "tabIndex", -1 )
          .tooltip()
          .appendTo( this.wrapper )
          .button({
            icons: {
              primary: "ui-icon-triangle-1-s"
            },
            text: false
          })
          .removeClass( "ui-corner-all" )
          .addClass( "custom-combobox-toggle ui-corner-right" )
          .on( "mousedown", function() {
            wasOpen = input.autocomplete( "widget" ).is( ":visible" );
          })
          .on( "click", function() {
            input.trigger( "focus" );
 
            // Close if already visible
            if ( wasOpen ) {
              return;
            }
 
            // Pass empty string as value to search for, displaying all results
            input.autocomplete( "search", "" );
          });
      },
 
      _source: function( request, response ) {
        var matcher = new RegExp( $.ui.autocomplete.escapeRegex(request.term), "i" );
        response( this.element.children( "option" ).map(function() {
          var text = $( this ).text();
          if ( this.value && ( !request.term || matcher.test(text) ) )
            return {
              label: text,
              value: text,
              option: this
            };
        }) );
      },
 
      _removeIfInvalid: function( event, ui ) {
 
        // Selected an item, nothing to do
        if ( ui.item ) {
          return;
        }
 
        // Search for a match (case-insensitive)
        var value = this.input.val(),
          valueLowerCase = value.toLowerCase(),
          valid = false;
        this.element.children( "option" ).each(function() {
          if ( $( this ).text().toLowerCase() === valueLowerCase ) {
            this.selected = valid = true;
            return false;
          }
        });
 
        // Found a match, nothing to do
        if ( valid ) {
          return;
        }
 
        // Remove invalid value
        this.input
          .val( "" )
          .attr( "title", "No se encuentra ningun resultado con " + value )
          .tooltip( "open" );
        this.element.val( "" );
        this._delay(function() {
          this.input.tooltip( "close" ).attr( "title", "" );
        }, 2500 );
        this.input.autocomplete( "instance" ).term = "";
      },
 
      _destroy: function() {
        this.wrapper.remove();
        this.element.show();
      }
    });
 
    $( "#select-empleados select" ).combobox();
	$( "#select-proveedores select" ).combobox();

  } );