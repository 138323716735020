
function marcaCampoError(obj,id_error) {
    var err = id_error || '';

    // Si nos pasan input de formulario, lo marcaremos con la class error
    if ( $(obj).length ) {
        var tipoObj = $(obj).attr('data-tipo');
        if (tipoObj == 'radio') {
            $(obj).closest('div.radios').addClass('error');
        } else if (tipoObj == 'checkbox') {
            $(obj).closest('label').addClass('error');
        } else {
            $(obj).addClass('error');
        }
    }

    // Mostramos el mensaje correspondiente
    if ( err !== '' && $('#titulo-pagina').find('.' + err).length ) {
        $('#titulo-pagina').find('.' + err).removeClass('oculto');
    }
}

function muestraMsgError(id_error,scroll_arriba) {
    var err = id_error || 'error_general';
    var scroll = typeof scroll_arriba === 'undefined' ? true : scroll_arriba;

    // Mostramos el mensaje correspondiente
    if (  $('#titulo-pagina').find('.' + err).length ) {
        $('#titulo-pagina').find('.' + err).removeClass('oculto');
    }

    if ( scroll ) {
        gototop(500);
    }
}

function checkEmail(email){ var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/; return regex.test(email);}

function valida_cif(cif) {

    //Quitamos el primer caracter y el ultimo digito
    var valueCif=cif.substr(1,cif.length-2);

    var suma=0;

    //Sumamos las cifras pares de la cadena
    for(var i=1;i<valueCif.length;i=i+2) {
        suma=suma+parseInt(valueCif.substr(i,1));
    }

    var suma2=0;
    //Sumamos las cifras impares de la cadena
    for(i=0;i<valueCif.length;i=i+2)
    {
        result=parseInt(valueCif.substr(i,1))*2;
        if(String(result).length==1)
        {
            // Un solo caracter
            suma2=suma2+parseInt(result);
        }else{
            // Dos caracteres. Los sumamos...
            suma2=suma2+parseInt(String(result).substr(0,1))+parseInt(String(result).substr(1,1));
        }
    }

    // Sumamos las dos sumas que hemos realizado
    suma=suma+suma2;

    var unidad = String(suma).substr(String(suma).length - 1, 1);
    unidad=10-parseInt(unidad);
    if(unidad==10) unidad=0;

    var primerCaracter=cif.substr(0,1).toUpperCase();
    var ultimoCaracter=cif.substr(cif.length-1,1);
    var lockup = 'JABCDEFGHI';

    if(primerCaracter.match(/^[NPQRSW]$/)) {
        //Empieza por .... el último caracter es una letra
        if( isInt(ultimoCaracter) ) return false;
        if(lockup.charAt(unidad)===ultimoCaracter) {
            return true;
        }
    }else if(primerCaracter.match(/^[ABCDEFGHJUV]$/)){
        //Empieza por .... el último caracter es un número

        if(!isInt(ultimoCaracter))
            return false; // No es un número

        if(ultimoCaracter===String(unidad))
            return true;
    }else if(primerCaracter.match(/^[KLM]$/)){
        //Empieza por .... el último caracter es un número o letra
        if(ultimoCaracter===String(unidad) || lockup.charAt(unidad)===ultimoCaracter)
            return true;
    }else if(primerCaracter.match(/^[XYZ]$/)){
        //Se valida como un dni
        var newcif;
        if(primerCaracter=="X")
            newcif=cif.substr(1);
        else if(primerCaracter=="Y")
            newcif="1"+cif.substr(1);
        else if(primerCaracter=="Z")
            newcif="2"+cif.substr(1);

        return es_dni_o_nie(newcif);
    }else{
        //Se valida como un dni
        return es_dni_o_nie(cif);
    }
    return false;
}

function isInt(n){
    return (!isNaN(parseInt(n, 10)));
}


function es_dni_o_nie(value){

    var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    var nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    var str = value.toString().toUpperCase();

    if (!nifRexp.test(str) && !nieRexp.test(str)) return false;

    var nie = str
        .replace(/^[X]/, '0')
        .replace(/^[Y]/, '1')
        .replace(/^[Z]/, '2');

    var letter = str.substr(-1);
    var charIndex = parseInt(nie.substr(0, 8)) % 23;

    return (validChars.charAt(charIndex) === letter);
}

function cp_provincia(f) {
    var provincia = $(f).find('#provincia').val();
    var cp = $(f).find('#cp').val();
    var cp2d = cp.substring(0, 2); // los 2 primeros digitos de cp

    if ( Number(cp2d) >= 1 && Number(cp2d) <= 52  ) {
        if (Number(provincia) != Number(cp2d)) {
            $(f).find('#provincia').val(cp2d);
        }
    } else {
        $(f).find('#provincia').val('');
    }
}

function provincia_cp(f) {
    var provincia = $(f).find('#provincia').val();
    var cp = $(f).find('#cp').val();
    var cp2d = cp.substring(0, 2);				// los 2 primeros digitos de cp

    if( Number(provincia) != Number(cp2d) ){
        $(f).find('#cp').val(provincia);
        $(f).find('#cp').focus();
    }

}
