$(document).ready(function() {


    $('#form-registro').find('input[type="button"]').on('click',function(e){

        var f = $('#form-registro');

        validar_campos(f);

        if ( $(f).find('.error').length == 0 ) {

            // lanzamos petición para crear empresa en bbdd
            var ajax_data = $(f).serialize();
            $.ajax({
                url: DIR_APP + 'ajax/put/empresa',
                data: ajax_data,
                type: 'post',
                dataType: 'json',
                success: function(json) {
                    if ( json.cres === 0 ) {

                        // vamos al paso de pago
                        location.href = $(f).attr('data-url-pasopago');

                    } else if ( json.cres == -10 ) {

                        if ( json.error == 'codigo' ) {
                            marcaCampoError($('#codigo'));
                            muestraMsgError('error_codigo');
                        } else {
                            muestraMsgError();
                        }
                    } else if ( json.cres == -11 ) {

                        if ( json.error == 'cif' ) {
                            marcaCampoError($('#cif_nif'));
                            muestraMsgError('error_cif');
                        } else if ( json.error == 'email' ) {
                            marcaCampoError($('#email'));
                            muestraMsgError('error_email');
                        } else {
                            muestraMsgError();
                        }

                    } else {
                        muestraMsgError('error_server');
                        console.log('No se ha podido guardar el contacto. Error = ' + json.cres);
                    }
                },
                error:function (xhr, ajaxOptions, thrownError) {
                    muestraMsgError('error_server');
                    console.log('No se ha podido guardar el contacto. Error AJAX');
                }
            });
        }
    });

    $('#form-registro,#form-misdatos').find('select[name="provincia"]').on('change',function() {
        provincia_cp($(this).closest('form'));
    });

    $('#form-registro,#form-misdatos').find('input[name="cp"]').on('keyup',function() {
        cp_provincia($(this).closest('form'));
        put_var_session('provincia',$('select[name="provincia"]').val());
    });

    var keytimeout = [];
    $('#form-registro').find('input[type="text"],input[type="email"]').on('keyup',function() {
        var nombre = $(this).attr('name');
        var valor = $(this).val();
        clearTimeout(keytimeout[nombre]);
        keytimeout[nombre] = setTimeout(function() { put_var_session(nombre,valor); },1000);
    });
    $('#form-registro').find('input[type="radio"]').on('click',function() {
        put_var_session($(this).attr('name'),$(this).val());
    });
    $('#form-registro').find('select').on('change',function() {
        put_var_session($(this).attr('name'),$(this).val());
    });

    $('#form-registro').find('select,input[type="text"],input[type="email"],input[type="radio"]').each(function(i) {
        if ( $(this).val() == '' ) {
            get_var_session($(this).attr('name'));
        }
    });


    $('#form-misdatos').find('input[type="button"]').on('click',function(e){

        var f = $('#form-misdatos');

        validar_campos(f);

        if ( $(f).find('.error').length == 0 ) {

            // lanzamos petición para actualizar la empresa en bbdd
            var ajax_data = $(f).serialize();
            $.ajax({
                url: DIR_APP + 'ajax/put/empresa',
                data: ajax_data,
                type: 'post',
                dataType: 'json',
                success: function(json) {
                    if ( json.cres === 0 ) {
                        // Mostramos mensaje
                        var params = {'titulo': $(f).attr('data-msg-titulo'),'mensaje': $(f).attr('data-msg-desc')};

                        if ( $(f).attr('data-msg-btn') != '' ) {
                            params.btn = $(f).attr('data-msg-btn');
                        }

                        if ( $(f).attr('data-msg-url') != '' ) {
                            params.url = $(f).attr('data-msg-url');
                        }

                        ponMensaje(params);

                    } else if ( json.cres == -10 ) {
                        muestraMsgError('error_general');
                    } else if ( json.cres == -11 ) {

                        if ( json.error == 'email' ) {
                            marcaCampoError($('#email'));
                            muestraMsgError('error_email');
                        } else {
                            muestraMsgError();
                        }
                    } else {
                        muestraMsgError('error_server');
                        console.log('No se ha podido guardar el contacto. Error = ' + json.cres);
                    }
                },
                error:function (xhr, ajaxOptions, thrownError) {
                    muestraMsgError('error_server');
                    console.log('No se ha podido guardar el contacto. Error AJAX');
                }
            });
        }
    });

    $('#form-politica-privacidad').find('input[type="button"]').on('click',function(e){

        var f = $('#form-politica-privacidad');

        validar_campos(f);

        if ( $(f).find('.error').length == 0 ) {

            // lanzamos petición para actualizar la política de privacidad
            var ajax_data = $(f).serialize();
            $.ajax({
                url: DIR_APP + 'ajax/put/politica-privacidad',
                data: ajax_data,
                type: 'post',
                dataType: 'json',
                success: function(json) {
                    if ( json.cres === 0 ) {
                        // Mostramos mensaje
                        var params = {'titulo': $(f).attr('data-msg-titulo'),'mensaje': $(f).attr('data-msg-desc')};

                        if ( $(f).attr('data-msg-btn') != '' ) {
                            params.btn = $(f).attr('data-msg-btn');
                        }

                        if ( $(f).attr('data-msg-url') != '' ) {
                            params.url = $(f).attr('data-msg-url');
                        }

                        ponMensaje(params);

                    } else if ( json.cres == -10 ) {
                        muestraMsgError('error_general');
                    } else {
                        muestraMsgError('error_server');
                        console.log('No se ha podido guardar el contacto. Error = ' + json.cres);
                    }
                },
                error:function (xhr, ajaxOptions, thrownError) {
                    muestraMsgError('error_server');
                    console.log('No se ha podido guardar el contacto. Error AJAX');
                }
            });
        }
    });

});


function validar_campos(f) {

    // Ocultamos mensajes de error
    $('#titulo-pagina .error').addClass('oculto');

    // Y quitamos error de los campos de form
    $(f).find('.error').removeClass('error');

    $(f).find('[data-requerido="requerido"],[data-tipo="email"],[data-tipo="telf"],[data-igual]').each(function(index) {

        // No lo comprobamos si está oculto
        var valor = $(this).val();

        if ( $(this).attr('data-requerido') == 'requerido' ) {

            switch( $(this).attr('data-tipo') ) {
                case 'radio':
                    if( $('[name='+ $(this).attr('name') + ']:checked').length == 0 ) {
                        marcaCampoError(this);
                    }
                    break;
                default:
                    if( valor == ""){
                        marcaCampoError(this);
                    }
                    break;
            }
        } else {
            if (valor == '') return; // Si no está especificado el valor, no lo comprobamos (si no se marca como requerido, solo se comprobará el formato si hay valor)
        }

        if( valor != '') {

            if ($(this).attr('data-tipo') == 'email') {
                if (!checkEmail(valor)) {
                    marcaCampoError(this);
                }
            }

            if ($(this).attr('data-tipo') == 'telf') {
                var primerNum = parseInt(valor.substring(0, 1));
                if (valor.length !== 9 || isNaN(valor) || !(primerNum === 9 || primerNum === 8 || primerNum === 7 || primerNum === 6)) {
                    marcaCampoError(this);
                }
            }

            if ($(this).attr('data-tipo') == 'cif' && valor !== '') {
                valor = valor.replace("-", "");
                valor = valor.replace(" ", "");
                valor = valor.toUpperCase();
                if (!valida_cif(valor)) {
                    marcaCampoError(this);
                }
            }

            var idCampoComparar = $(this).attr('data-igual');
            if ($(f).find('[name="' + idCampoComparar + '"]').length > 0) {
                if (valor != $(f).find('[name="' + idCampoComparar + '"]').val()) {
                    marcaCampoError(this);
                }
            }
        }
    });

    // Si hay error, mostramos
    if ( $(f).find('.error').length > 0 ) {
        muestraMsgError();
    }
}