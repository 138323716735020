
function get_var_session(clave) {
    $.ajax({
        url: DIR_APP + 'ajax/get/var_session',
        data: {k: clave},
        type: 'post',
        dataType: 'json',
        success: function(json) {
            // Actualizo campo en el form
            if( $('[name="'+clave+'"]').length ) {
                if ($('[name="' + clave + '"]').attr('type') === 'radio') {
                    $('[name="' + clave + '"][value="' + json.valor + '"]').prop('checked', true);
                } else {
                    $('[name="' + clave + '"]').val(json.valor);
                }
            }
        },
        error:function (xhr, ajaxOptions, thrownError) {
            console.log('No se ha podido obtener el campo de sesión. Error AJAX');
        }
    });
}

function put_var_session(clave,valor) {
    $.ajax({
        url: DIR_APP + 'ajax/put/var_session',
        data: {k: clave, v: valor},
        type: 'post',
        dataType: 'json',
        success: function(json) {
            // Guardo ok en sesión
        },
        error:function (xhr, ajaxOptions, thrownError) {
            console.log('No se ha podido guardar el campo en sesión. Error AJAX');
        }
    });
}