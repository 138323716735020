$(document).ready(function() {
    $('div.riesgos').on('click','span.punto',function(e) {

        // Marcamos el punto
		console.log($(this).attr("data-idriesgo"));
		
		/*$("div.riesgos ul li .toggleRiesgo").removeClass("open");
		$("div.riesgos ul li ul").slideUp(0);*/
		
		$("div.riesgos ul li[data-idriesgo="+$(this).attr("data-idriesgo")+"] .toggleRiesgo").addClass("open");
		$("div.riesgos ul li[data-idriesgo="+$(this).attr("data-idriesgo")+"] ul").slideDown();
		
		// Hacemos scroll automatico 
		var scrollTop = Math.max($('html, body').scrollTop(), $(document).scrollTop(), $(window).scrollTop());

		var position = $("div.riesgos ul li[data-idriesgo="+$(this).attr("data-idriesgo")+"]").position().top - parseInt($("div.grafica").css("padding-top"));
		var base_speed  = 1000; // Time in ms per 1,000 pixels
		var speed       = Math.abs(((position-scrollTop) * base_speed) / 1000);
		
		console.log("speed: ",speed);
		
		$('html, body').stop().animate({
			scrollTop: position
		}, speed);	


    });
	if($('.grafica.riesgos').length){
		$(document).on("scroll",scrollGrafica);
		$(window).on('changed.zf.mediaquery, resize', scrollGrafica);
	}

    $('.toggleRiesgo, .riesgos_listado li div span').on("click",function(e){
        e.preventDefault();
        $(this).closest('li').find('ul').slideToggle({'progress': scrollGrafica});
        $(this).closest('div').find('.toggleRiesgo').toggleClass('open');
    });
});

function scrollGrafica(){
	if(Foundation.MediaQuery.current == "small") {
		$('.grafica.riesgos').css("margin-top",0);
	}else{
		var scrollTop = Math.max($('html, body').scrollTop(), $(document).scrollTop(), $(window).scrollTop());
		var diferencia = $('.grafica.riesgos').position().top-scrollTop;
		//console.log(diferencia);
		$('.grafica.riesgos').css("margin-top",Math.min(Math.max(0,diferencia*-1),$('.listado.riesgos > div').height()-$('.grafica.riesgos > div').height()));
	}
}