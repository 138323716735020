$(document).ready(function() {


    $('#form-pago').find('a.btn').on('click',function(e) {
        e.preventDefault();

        // comprobación de checkbox
        if ( $('input[name="acepto_condiciones_generales"]:checked').length === 0 ) {
            muestraMsgError();
            return;
        }

        var f = $('#form-pago');

        // lanzamos petición para crear empresa en bbdd
        var ajax_data = $(f).serialize();
        $.ajax({
            url: DIR_APP + 'ajax/put/pago',
            data: ajax_data,
            type: 'post',
            dataType: 'json',
            success: function(json) {
                if ( json.cres === 0 ) {
                    $(f).find('input[name="Ds_SignatureVersion"]').val(json.version);
                    $(f).find('input[name="Ds_MerchantParameters"]').val(json.params);
                    $(f).find('input[name="Ds_Signature"]').val(json.signature);
                    $(f).submit();
                } else if ( json.cres == -10 ) {

                    $(f).find('.txt_error').removeClass('oculto');
                    if ( json.error == 'codigo' ) {
                        $(f).find('.txt_error p.error_codigo').removeClass('oculto');
                    } else {
                        $(f).find('.txt_error p.error_general').removeClass('oculto');
                    }

                } else {
                    $(f).find('.txt_error_server').removeClass('oculto');
                    console.log('No se ha podido completar el pago. Error = ' + json.cres);
                }
            },
            error:function (xhr, ajaxOptions, thrownError) {
                $(f).find('.txt_error_server').removeClass('oculto');
                console.log('No se ha podido completar el pago. Error AJAX');
            }
        });

    });

});
