

$(document).ready(function() {

    $('#form-login form').on('submit', function(e) {
        e.preventDefault();
        e.stopPropagation();

        var f = $(this);
        var user = $(this).find('input[name="user"]');
        var pass = $(this).find('input[name="pass"]');

        if( $(user).val() === '' ) {
            ponMensaje({'mensaje':'Escribe tu CIF/NIF', 'url': function (e) {
                    e.preventDefault();
                    $(f).find('[name="user"]').focus();
                    $("#ventana_cover").hide();
                }});
            return;
        }

        if( $(pass).val() === '' ) {
            ponMensaje({'mensaje':'Escribe tu contraseña', 'url': function (e) {
                    e.preventDefault();
                    $(f).find('[name="pass"]').focus();
                    $("#ventana_cover").hide();
                }});
            return;
        }

        sendLogin($(user).val(),$(pass).val());
    });

    $('#form-rec-pass form').on('submit', function(e) {
        e.preventDefault();
        e.stopPropagation();

        var f = $(this);
        var email = $(this).find('input[name="email"]');

        if( $(email).val() === '' ) {
            ponMensaje({'mensaje':'Escribe el email con el que te registraste', 'url': function (e) {
                e.preventDefault();
                $(f).find('[name="email"]').focus();
                $("#ventana_cover").hide();
            }});
            return;
        }

        sendRecPass($(email).val());
    });

    $('a.rec_pass').on('click',function(e){
        e.preventDefault();

        $('#form-login').addClass('oculto');
        $('#form-rec-pass').removeClass('oculto');

    });

    $('a.volver-login').on('click',function(e){
        e.preventDefault();

        $('#form-rec-pass').addClass('oculto');
        $('#form-login').removeClass('oculto');
    });


});

function sendLogin(user,pass){

    var ajax_data = {user:user, pass:pass};
    $.ajax({
        url: DIR_APP + 'ajax/login',
        data: ajax_data,
        type: 'post',
        dataType: 'json',
        success: function(json) {
            if ( json.cres === 0 ) {
                gotoPage(SLUG_MIRGPD);
            } else {
                ponMensaje({'titulo':'Acceso incorrecto','mensaje':'Parece que los datos que has proporcionado no corresponden a ningún usuario activo.<br /><br />Si no recuerdas tu contraseña, utiliza el acceso para poder actualizarla.<br /><br />¡Gracias!'});
            }
        },
        error:function (xhr, ajaxOptions, thrownError) {
            console.log('fail login',thrownError);
        }
    });
}

function sendRecPass(email){

    var ajax_data = {email:email};
    $.ajax({
        url: DIR_APP + 'ajax/rec-pass',
        data: ajax_data,
        type: 'post',
        dataType: 'json',
        success: function(json) {
            if ( json.cres === 0 ) {
                $('#form-rec-pass').addClass('oculto');
                $('#form-login').removeClass('oculto');

                ponMensaje({'titulo':'¡Ya tienes nueva contraseña!','mensaje':'Te hemos mandado un <strong>email para que puedas actualizar tu contraseña</strong> a la dirección de correo que nos indicaste (revisa tu bandeja de correo no deseado o spam, por favor):<br /><br />' + email + '<br /><br /><i>Recuerda que, por tu seguridad, te pediremos que modifiques tu contraseña la primera vez que te identifiques.</i>'});
            } else {
                ponMensaje({'titulo':'Solicitud nueva contraseña','mensaje':'Parece que ha habido algún problema al enviar el <strong>email para que puedas actualizar tu contraseña</strong>. Comprueba que la dirección de correo "' + email + '" sea correcta.<br /><br />¡Gracias!'});
            }
        },
        error:function (xhr, ajaxOptions, thrownError) {
            console.log('fail login',thrownError);
        }
    });
}

function closeSession(){

  $.post( DIR_APP + 'ajax/logout' , function( data ) {
    if(data!=null && data!=undefined){
      if(data.cres==0){
            gotoPage('');
      }else{
         console.log("fail data: ",data);
      }
    }
  },'json')
  .fail(function() {
    console.log("fail");
    gotoPage('');
  });
}

// Header
$("#close-session-btn").on("click", closeSession );

function gotoPage(id,subId){
    var url_ = DIR_APP + id + ((subId!=undefined)?('/'+subId):'');
    window.location = url_;
}





