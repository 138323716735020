
$(document).ready(function() {

    $('#sel_empresa [data-idempresa]').on('click', function(e) {

        $.ajax({
            url: DIR_APP + 'ajax/select-empresa',
            data: {'idemp' : $(this).attr('data-idempresa')},
            type: 'post',
            dataType: 'json',
            success: function(json) {
                if ( json.cres === 0 ) {
                    gotoPage(SLUG_MIRGPD);
                } else {
                    muestraMsgError('error_server');
                    console.log('No se ha podido seleccionar la emrpesa. Error = ' + json.cres);
                }
            },
            error:function (xhr, ajaxOptions, thrownError) {
                muestraMsgError('error_server');
                console.log('fail ajax',thrownError);
            }
        });
    });

});
