
$(document).ready(function() {
	$(document).foundation();
	/*$(".registrate h3").on("click",function(){
		$("html, body").stop().animate({scrollTop:0}, 500, 'swing', function() {});
	});*/
	
	// comprobamos si existe la cookie de aceptación de cookies técnicas
	if ( Cookies.get('rgpd_accept_cookie_tech') !== 'ok' ) {
        $('#cookies').show();
    }

    $('#ventana_cookie a.aceptar_cookies, #cookies a.btn').on('click',function(e) {
        e.preventDefault();

        if ( Cookies.get('rgpd_accept_cookie_tech') !== 'ok' ) {
            // Creamos la cookie durante 1 año para no mostrarsela siempre al usuario
            Cookies.set('rgpd_accept_cookie_tech', 'ok', { expires: 365, path: '/' });
            if ( $(this).hasClass('btn') ) {
                setTimeout(function () {
                    $('#cookies').fadeTo(400, 0).slideUp(400);
                }, 1000);
            }
        }

        if ( $(this).hasClass('aceptar_cookies') ) {
            $('#ventana_cookie').fadeOut();
        }
    });

	// la de analytics, me la guardo de inicio directamente, ya la quitará el usuario si quiere
    if ( Cookies.get('rgpd_accept_cookie_analytics') === undefined ) {
        Cookies.set('rgpd_accept_cookie_analytics', 'ok', {expires: 365, path: '/'});
    }

	// Cargamos, en el layer de configuración de cookies, el valor de la cookie de analytics
    if ( Cookies.get('rgpd_accept_cookie_analytics') === 'ok' ) {
        $('input[name="cookies_analytics"][value="Sí"]').prop('checked', true).attr('checked','checked');
        $('input[name="cookies_analytics"][value="No"]').prop('checked', false).removeAttr('checked');
    } else {
        $('input[name="cookies_analytics"][value="Sí"]').prop('checked', false).removeAttr('checked');
        $('input[name="cookies_analytics"][value="No"]').prop('checked', true).attr('checked','checked');
	}

    $('input[name="cookies_analytics"]').on('click',function(e) {
        // Actualizamos la cookie en función del valor
        if ($('input[name="cookies_analytics"]:checked').val() === 'Sí') {
            Cookies.set('rgpd_accept_cookie_analytics', 'ok', {expires: 365, path: '/'});
        } else {
            Cookies.set('rgpd_accept_cookie_analytics', 'ko', {expires: 365, path: '/'});
        }
    });

	$('a.configurar_cookies').on('click',function(e) {
		e.preventDefault();
		$('#ventana_cookie').show("fast");
	});
	
	$("#home h2").on("click",{clase: ".grid-container"},gotoAnchor);
	$("#contacto h2").on("click",{clase: ".cell"},gotoAnchor);
	$("#registro h2").on("click",{clase: ".cell"},gotoAnchor);
	
	$(".login .identificate strong").on("click",toogleLogin);
	$(window).on('changed.zf.mediaquery', function(event, newSize, oldSize){
		cerrarLogin(0);
		$(".logged_nav ul").removeClass("opened");	
	});
	
	$(document).on("click",".logged_nav li.toggle a",function(e){
		e.preventDefault();
		$(".logged_nav ul").toggleClass("opened");		
	});
	//ponMensaje({titulo: "Titular prueba", mensaje:"Pues mira que bien, puedo poner hasta <strong>HTML</strong>.<br />Es un mensaje de estos tipicos algo mas largo", btn:'Acceptar', url:'/contacto'});

});

function toogleLogin(){
	if(Foundation.MediaQuery.current == "small") {
		$(".login #form-login form").slideToggle();
		$(".login #form-rec-pass form").slideToggle();
    }
}
function cerrarLogin(tiempo){
	$(".login #form-login form").slideUp(tiempo);
	$(".login #form-rec-pass form").slideUp(tiempo);
}

function gotoAnchor(e){	
	var scrollTop = Math.max($('html, body').scrollTop(), $(document).scrollTop(), $(window).scrollTop());
	
	var position = $(this).closest(e.data.clase).position().top;
	var base_speed  = 1000; // Time in ms per 1,000 pixels
  	var speed       = ((position-scrollTop) * base_speed) / 1000;

    $('html, body').stop().animate({
        scrollTop: position
    }, speed);
}

function gototop(spd){
	var speed = spd || 0;
    $('html, body').stop().animate({
        scrollTop: 0
    }, speed);
}

function ponMensaje(data){


	if ( data.container_id!=null && $('#'+data.container_id).length > 0 ) {
		// toda la definición de la ventana está en un id, cogemos la info de ahí
        $('#mensaje').html($('#'+data.container_id).html());
        $("#ventana_cover").show();
        $("#ventana_cover .cerrar").attr("href", $("#ventana_cover .btn1").attr('href'));
	} else {
		// la definición vienen en variables
        $("#ventana_cover, #ventana_cover .titular, #ventana_cover .texto, #ventana_cover .btn1").show();
        if (data.titulo != null) {
            $("#ventana_cover .titular").show();
            $("#ventana_cover .titular").html(data.titulo);
        }
        else {
            $("#ventana_cover .titular").hide();
        }

        if (data.mensaje != null) {
            $("#ventana_cover .texto").show();
            $("#ventana_cover .texto").html(data.mensaje);
        }
        else {
            $("#ventana_cover .texto").hide();
        }

        if (data.btn != null) $("#ventana_cover .btn1").html(data.btn);
        else $("#ventana_cover .btn1").html(TXT_CERRAR);

        if (data.btn2 != null) {
            $("#ventana_cover .btn2").show();
            $("#ventana_cover .btn2").html(data.btn2);
        }
        else {
            $("#ventana_cover .btn2").hide();
        }

        $("#ventana_cover .btn1").off("click");
        if (data.url == null) {
            $("#ventana_cover .btn1").on("click", cerrarMensaje);
            $("#ventana_cover .cerrar").on("click", cerrarMensaje);
        } else {

            if (typeof data.url == 'function') {
                $("#ventana_cover .btn1").on("click", data.url);
                $("#ventana_cover .cerrar").on("click", data.url);
            } else {
                $("#ventana_cover .btn1, #ventana_cover .cerrar").attr("href", data.url);
            }
        }

        if (data.btn2 != null && data.url2 != null) {
            $("#ventana_cover .btn2").off("click");

            if (typeof data.url2 == 'function') {
                $("#ventana_cover .btn2").on("click", data.url2);
            } else {
                $("#ventana_cover .btn2").attr("href", data.url2);
            }
        }
    }

}
function cerrarMensaje(e){
	if(e) e.preventDefault();
	$("#ventana_cover").hide();
}
