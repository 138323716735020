$(document).ready(function() {

    // Ponemos el título de página (nombre ámbito) en la primera
    if ( $('#formulario-ambitos').length ) {
        if ( $('[data-idpagina="1"] > span.txt-titulo-ambito').length ) {
            $('#titulo-pagina > h2').html('1. ' + $('[data-idpagina="1"] > span.txt-titulo-ambito').html());
        }

        var numPaginas = $('div[data-idpagina]').length;

        for(var p=numPaginas+1;p<=5;p++) {
            $('a.numeros_pagina[data-idpag="'+p+'"]').closest('li').addClass('oculto');
        }
    }

    $('a.numeros_pagina').on("click",function(e){
        e.preventDefault();

        var pag = $(this).attr('data-idpag');

        $('div.paginacion > ol > li').removeClass('sel');
        $(this).closest('li').addClass('sel');

        var objPag = $('[data-idpagina="'+pag+'"]');
        if ($(objPag).length) {
            $('[data-idpagina]').addClass('oculto');
            $(objPag).removeClass('oculto');
            $('#titulo-pagina > h2').html($(objPag).attr('data-idpagina') + '. ' + $(objPag).children('span.txt-titulo-ambito').html());
            gototop(500);
        }

        // mostramos botón de anterior
        if ( pag > 1 ) {
            $('#formulario-ambitos input.btn.anterior').removeClass('oculto');
        } else {
            $('#formulario-ambitos input.btn.anterior').addClass('oculto');
        }

        // Miramos si es la última página, ya que entonces mostraremos el Finalizar
        if ( pag == 5 ) {
            $('#formulario-ambitos input.btn.siguiente').val('Guardar y Finalizar');
        } else {
            $('#formulario-ambitos input.btn.siguiente').val('Guardar y Continuar');
        }

    });


    $('.btn_imprimir').on("click",function(e) {
        e.preventDefault();
        var urldown = $(this).attr('data-urldownload');
        window.open(urldown);
    });

    // Llamada inicial para recalculo de %
    calcPercent();

    // Al cargar, si ya hay algo de porcetaje, mostramos los ticks
    if ( $("header div.loading > div span").html() !== '0%' ) {
        marcaTicks($('#formularios form:first-child'));
    }

    $('.dt_fecha').fdatepicker({language: 'es'})
        .on('changeDate', function (ev) {

            var inputfini = $(ev.target).closest('div.info_accion').find('input.finicio').attr('data-isovalue');
            var fini = new Date(inputfini);

            if (ev.date) {
                if (ev.date.valueOf() < fini.valueOf()) {
                    $(ev.target).addClass('error');
                    ponMensaje({'mensaje': 'La fecha no puede ser inferior a la de inicio.'});
                } else {
                    $(ev.target).removeClass('error');
                }
            }
        });
	
	$('.toggleSlide').on("click",function(e){
		e.preventDefault();
		$(this).closest('li').find('.tabla').slideToggle();
		$(this).closest('li').find('.info_accion > div').slideToggle();
		$(this).toggleClass('open');
	});
	
    $('.radios input').on("click",function(e){

        var divInfo = $('#info_' + $(this).attr('name'));
        if ( $(divInfo).length ) {
			console.log($(this).val());
            if ( $(this).val() === 'Sí' || $(this).val() === 'Aplicada') {
                $(divInfo).find('.tabla li:first-child, .tabla li:nth-child(2)').removeClass("oculto");
				$(divInfo).find('.tabla').slideDown();
				$(divInfo).find('> div').slideDown();
				$(this).closest('li').addClass('opened');
				$(this).closest('li').find('.toggleSlide').addClass('open');
            } else {
				$(divInfo).find('.tabla li:first-child, .tabla li:nth-child(2)').addClass("oculto");
                $(divInfo).find('.tabla').slideUp();
				$(divInfo).find('> div').slideUp();
				$(this).closest('li').removeClass('opened');
				$(this).closest('li').find('.toggleSlide').removeClass('open');
            }
        }

        // Sí tienes clase KO, la ponemos en OK
        var idForm = $('#formularios form:first-child').attr('id');
        if ( idForm !== 'formulario-plan_accion' && $(this).closest('li[data-idpregunta]').hasClass('ko') ) {
            $(this).closest('li[data-idpregunta]').removeClass('ko').addClass('ok');
        }

        // Miramos si tiene alguna pregunta de la que dependa
        if ( $(this).closest('li[data-idpregunta]').length ) {
            trata_visibilidad($(this).closest('li[data-idpregunta]').attr('data-idpregunta'), $(this).val());
        }

        calcPercent();
    });


    $('div.empleados').on('click','div.btn_eliminar a',function(e) {
        e.preventDefault();

        var divItem = $(this).closest('div.item-real');

        // Pedimos confirmación
        ponMensaje({'mensaje':'Se va a proceder a la eliminación de este empleado. ¿Está seguro?','btn':'Sí','btn2':'No','url':function(e){
                e.preventDefault();

                $(divItem).remove();
                $("#num_empleados").val($("div.empleados div.item-real").length);
                cerrarMensaje();

            },'url2':cerrarMensaje});

    });

    $('li.proveedor-info').on('click','div.btn_eliminar a',function(e) {
        e.preventDefault();

        if ( $('li.proveedor-info').length <= 1 ) {
            ponMensaje({'mensaje':'Este último proveedor no se puede eliminar. Para eliminarlos todos, responda "No" a la pregunta 3.11.'});
            return;
        }

        var divItem = $(this).closest('li[data-idpregunta]');

        // Pedimos confirmación
        ponMensaje({'mensaje':'Se va a proceder a la eliminación de este proveedor. ¿Está seguro?','btn':'Sí','btn2':'No','url':function(e){
                e.preventDefault();

                $('li[data-idpregunta="'+$(divItem).attr('data-idpregunta')+'c"').remove(); // Borramos pregunta de cláusula
                $(divItem).remove(); // Y la del texto del proveedor
                cerrarMensaje();

            },'url2':cerrarMensaje});

    });

    $('#formulario-tratamientos').find('input[type="button"]').on('click',function(e) {

        var f = $(this).closest('form');

        // Ocultamos mensajes de error
        $('#titulo-pagina').find('.error').addClass('oculto');

        marcaTicks(f);

        // lanzamos petición para guardar respuestas del paso 1
        var ajax_data = $(f).serialize();
        $.ajax({
            url: DIR_APP + 'ajax/put/formularios',
            data: ajax_data,
            type: 'post',
            dataType: 'json',
            success: function(json) {
                if ( json.cres === 0 ) {
                    // Mostramos mensaje
                    var id_ventana = ($(f).find('li[data-idpregunta].ko').length === 0) ? 'ventana_ok' : 'ventana_ko';
                    ponMensaje({'container_id': id_ventana});

                } else if ( json.cres == -10 ) {
                    muestraMsgError();
                } else {
                    muestraMsgError('error_server');
                    console.log('No se ha podido guardar el formulario. Error = ' + json.cres);
                }
            },
            error:function (xhr, ajaxOptions, thrownError) {
                muestraMsgError('error_server');
                console.log('No se ha podido guardar el formulario. Error AJAX');
            }
        });
    });

    $('#formulario-plan_accion').find('input[type="button"]').on('click',function(e) {

        var f = $(this).closest('form');

        // Quitamos errores en campos del form
        $(f).find('.error').removeClass('error');

        // Ocultamos mensajes de error
        $('#titulo-pagina').find('.error').addClass('oculto');

        marcaTicks(f);

        if ( $(f).find('.error').length == 0 ) {
            // lanzamos petición para guardar respuestas del paso 1
            var ajax_data = $(f).serialize();
            $.ajax({
                url: DIR_APP + 'ajax/put/formularios',
                data: ajax_data,
                type: 'post',
                dataType: 'json',
                success: function (json) {
                    if (json.cres === 0) {

                        if ( json.perfil_privacidad_incompleto == 1 ) {
                            // Mostramos copy informativo que el perfil de privacidad vuelve a estar incompleto
                            $('#ventana_ok p.perfpriv-incompl').removeClass('oculto');
                        }

                        // Mostramos mensaje
                        ponMensaje({'container_id': 'ventana_ok'});

                    } else if (json.cres == -10) {
                        muestraMsgError();
                    } else {
                        muestraMsgError('error_server');
                        console.log('No se ha podido guardar el formulario. Error = ' + json.cres);
                    }
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    muestraMsgError('error_server');
                    console.log('No se ha podido guardar el formulario. Error AJAX');
                }
            });
        } else {
            muestraMsgError();
            gototop(500);
        }
    });

    $('#formulario-ambitos input.btn').on('click',function(e) {

        if( $('[data-idpagina]').not('.oculto').length ) {

            var error = false;
            var p = $('[data-idpagina]').not('.oculto');

            // Ocultamos mensajes de error
            $('#titulo-pagina').find('.error').addClass('oculto');

            marcaTicks(p);

            if ( !$("div.empleados").hasClass('oculto') && $("div.empleados div.item-real").length ) {

                $("#num_empleados").val($("div.empleados div.item-real").length);

                $("div.empleados div.item-real").each(function (i) {
                    var result = validaEmpleado(this);
                    var elemEmpleado = $(this);
                    if (result.error) {
                        ponMensaje({
                            'mensaje': result.mensaje, 'url': function (e) {
                                e.preventDefault();
                                $(elemEmpleado).find('[name^=' + result.campo + ']').focus();
                                $("#ventana_cover").hide();
                            }
                        });
                        error = true;
                        return false;
                    }
                });
            }

            if (!error) {
                // lanzamos petición para guardar respuestas
                var ajax_data = {'paso': 'ambitos'};

                $(p).find('input[type="hidden"],input[type="text"],input[type="radio"]:checked,input[type="checkbox"]:checked,select,textarea').each(function (i) {

                    if ( $(this).attr('data-nosend') === '1' ){
                        // Si está marcado para no enviar, saltamos al siguiente
                        return;
                    }

                    var tipoInp = $(this).attr('type');
                    var multiple = false;
                    switch (tipoInp) {
                        case 'radio':
                            if (typeof ajax_data[$(this).attr('name')] === 'undefined') ajax_data[$(this).attr('name')] = '';

                            if ($(this).is(':checked')) {
                                ajax_data[$(this).attr('name')] = $(this).val();
                            }
                            break;
                        case 'checkbox':
                            if (typeof ajax_data[$(this).attr('name')] === 'undefined') ajax_data[$(this).attr('name')] = [];

                            if ($(this).is(':checked')) {
                                ajax_data[$(this).attr('name')].push($(this).val());
                            }
                            break;
                        default:
                            if ( $('[name="'+$(this).attr('name')+'"]').length > 1 ) {
                                multiple = true;
                            }

                            if (typeof ajax_data[$(this).attr('name')] === 'undefined') {
                                ajax_data[$(this).attr('name')] = (multiple) ? [] : '';
                            }

                            if ( multiple ) {
                                ajax_data[$(this).attr('name')].push($(this).val());
                            } else {
                                ajax_data[$(this).attr('name')] = $(this).val();
                            }
                    }

                });

                // mandamos el número de proveedores que hay
                //ajax_data.num_proveedores = $('input[type="hidden"][name="num_proveedor[]"]').length;

                var funcOK = $(this).hasClass('siguiente') ? siguientePag : anteriorPag;

                $.ajax({
                    url: DIR_APP + 'ajax/put/formularios',
                    data: ajax_data,
                    type: 'post',
                    dataType: 'json',
                    success: function (json) {
                        if (json.cres === 0) {

                            // pasamos a la siguente/anterior página
                            funcOK();
                            calcPercent();

                        } else if (json.cres == -10) {
                            muestraMsgError();
                        } else {
                            muestraMsgError('error_server');
                            console.log('No se ha podido guardar el formulario. Error = ' + json.cres);
                        }
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        muestraMsgError('error_server');
                        console.log('No se ha podido guardar el formulario. Error AJAX');
                    }
                });
            }
        }
    });

});

function trata_visibilidad(idp,valresp) {

    var pr = $('li[data-pregunta_depende="'+ idp +'"]');
    if ( $(pr).length ) {

        $(pr).each(function(i) {

            var miIdP = $(this).attr('data-idpregunta');
            var miRes = '0';
            if ( valresp == $(this).attr('data-respuesta_depende') ) {
                $(this).removeClass('oculto');

                if ( $(this).find('input[name="respuesta_p' + miIdP + '"]:checked').length ) {
                    miRes = $(this).find('input[name="respuesta_p' + miIdP + '"]:checked').val();
                }
            } else {
                $(this).addClass('oculto');
                var inp = $(this).find('input[name="respuesta_p' + miIdP + '"]');
                if ( $(inp).attr('type') == 'text' ) {
                    $(inp).val('');
                } else {
                    $(inp).prop('checked', false).attr('checked', '');
                }
            }
            trata_visibilidad(miIdP, miRes);

        });
    }
}

function siguientePag() {
    if( $('[data-idpagina]').not('.oculto').length ) {
        var formSiguiente = $('[data-idpagina]').not('.oculto').next('[data-idpagina]');

        if ($(formSiguiente).length) {

            $('div.paginacion > ol > li').removeClass('sel');
            $('a.numeros_pagina[data-idpag="'+$(formSiguiente).attr('data-idpagina')+'"]').closest('li').addClass('sel');

            $('[data-idpagina]').addClass('oculto');
            $(formSiguiente).removeClass('oculto');
            $('#titulo-pagina > h2').html($(formSiguiente).attr('data-idpagina') + '. ' + $(formSiguiente).children('span.txt-titulo-ambito').html());
            gototop(500);
        } else {
            // No hay siguiente página, nos quedamos en la misma y mostramos mensaje
            var f = $('#formulario-ambitos');

            var id_ventana = ($(f).find('li[data-idpregunta].ko,li[data-idpregunta] div.item-real.ko').not('.oculto').length === 0) ? 'ventana_ok' : 'ventana_ko';
            ponMensaje({'container_id': id_ventana});
        }

        // mostramos botón de anterior
        $('#formulario-ambitos input.btn.anterior').removeClass('oculto');

        // Miramos si es la última página, ya que entonces mostraremos el Finalizar
        if ($(formSiguiente).next('[data-idpagina]').length === 0) {
            $('#formulario-ambitos input.btn.siguiente').val('Guardar y Finalizar');
        }

    }

}

function anteriorPag() {
    if( $('[data-idpagina]').not('.oculto').length ) {
        var formAnterior = $('[data-idpagina]').not('.oculto').prev('[data-idpagina]');

        if ($(formAnterior).length) {

            $('div.paginacion > ol > li').removeClass('sel');
            $('a.numeros_pagina[data-idpag="'+$(formAnterior).attr('data-idpagina')+'"]').closest('li').addClass('sel');

            $('[data-idpagina]').addClass('oculto');
            $(formAnterior).removeClass('oculto');
            $('#titulo-pagina > h2').html($(formAnterior).attr('data-idpagina') + '. ' + $(formAnterior).children('span.txt-titulo-ambito').html());
            gototop(500);
        }

        // mostramos botón de siguiente
        $('#formulario-ambitos input.btn.siguiente').removeClass('oculto');
        $('#formulario-ambitos input.btn.siguiente').val('Guardar y continuar');

        // Miramos si ocultar anterior
        if ($(formAnterior).prev('[data-idpagina]').length === 0) {
            $('#formulario-ambitos input.btn.anterior').addClass('oculto');
        }
    }
}

function calcPercent(){
	var total = $('ol.preguntas li[data-idpregunta]:not(.oculto)').length;

	if ( total===0 ) {
        $("header div.loading").addClass('oculto');
        return;
    }

    var respondidas = 0;

    var idForm = $('#formularios form:first-child').attr('id');
    if ( idForm === 'formulario-plan_accion') {
        // En plan de acción, solo miramos las aplicadas
        respondidas = $('ol.preguntas li[data-idpregunta]:not(.oculto) input[value="Aplicada"]:checked').length;

    } else if ( idForm === 'formulario-tratamientos') {
        // RESPONDIDAS: Sólo los radios
        respondidas = $('ol.preguntas li[data-idpregunta]:not(.oculto) input:checked').length;

    } else if ( idForm === 'formulario-ambitos') {
        // RESPONDIDAS: Primero los radios
        respondidas = $('ol.preguntas li[data-idpregunta]:not(.oculto) input:checked').length;

        // Ahora inputs text
        $('ol.preguntas li[data-idpregunta]:not(.oculto) input[type="text"][name^="respuesta_p"],ol.preguntas li[data-idpregunta]:not(.oculto) input[type="text"][name^="proveedor_info"]').each(function(i) {
            if ($(this).val() != '') { respondidas++; }
        });

        // Ahora empleados
        var numEmpleadosRellenados = 0;
        $('div.empleados div.item-real').each(function (i) {
            var inputsNoVacios = 0;
            $(this).find('input[type="text"]').each(function (j) {
                if ($(this).val() != '') {
                    inputsNoVacios++;
                }
            });
            if (inputsNoVacios > 0) {
                numEmpleadosRellenados++;
            }
        });

        if ( numEmpleadosRellenados > 0 ) {
            respondidas++;
        }
    }

	var percent = String(Math.round(respondidas/total*100));
	
	$("header div.loading > div").css("width",percent+'%');
	$("header div.loading > div span").html(percent+'%');
    $('span.avance_numerico').html('('+respondidas+'/'+total+')');

	if ( percent == 100 ) {
        $("header div.loading").addClass('completo');
    } else {
        $("header div.loading").removeClass('completo');
    }

    if ( percent > 92 ) {
        $("header div.loading").addClass('outpercent');
    } else {
        $("header div.loading").removeClass('outpercent');
    }

}

function marcaTicks(parent){

    var f = $(parent);
    var idForm = $(f).is('form') ? $(f).attr('id') : $(f).closest('form').attr('id');
    if ( idForm === 'formulario-plan_accion') {

        $(f).find('li[data-idpregunta]').removeClass('ok').removeClass('ko');

        $(f).find('input[type="radio"][value="Aplicada"]:checked').each(function(i) {
            var lipreg = $(this).closest('li[data-idpregunta]');

            $(lipreg).find('input[type="text"][data-requerido="requerido"]').each(function(j) {
                if ( $(this).val() == '' ) {
                    marcaCampoError(this);
                    if ( $(lipreg).find('.toggleSlide').not('.open') ) {
                        $(lipreg).find('.toggleSlide').addClass('open');
                        $(lipreg).find('.tabla').slideDown();
                        $(lipreg).find('.info_accion > div').slideDown();
                    }
                }
            });

            $(lipreg).addClass( ( $(lipreg).find('.error').length === 0 ) ? 'ok' : 'ko' );
        });

        // A todas las pendientes las poniemos en KO
        $(f).find('input[type="radio"][value="Pendiente"]:checked').each(function(i) {
            var lipreg = $(this).closest('li[data-idpregunta]');
            $(lipreg).addClass('ko');
        });

        // Y comprobamos fechas
        $(f).find('li[data-idpregunta]').each(function(i) {

            $(this).find('input[type="text"].dt_fecha').each(function(j) {
                if ( $(this).val() != '' ) {
                    var lipreg = $(this).closest('li[data-idpregunta]');

                    // miramos que la fecha sea posterior a la de inicio
                    var inputfini = $(this).closest('div.info_accion').find('input.finicio').attr('data-isovalue');
                    var fini = new Date(inputfini);

                    var finput = $(this).val();
                    var fiso = finput.substr(6,4) + '-' + finput.substr(3,2) + '-' + finput.substr(0,2);
                    var factual = new Date(fiso);

                    if ( factual.valueOf() < fini.valueOf() ) {
                        marcaCampoError(this);
                        if ( $(lipreg).find('.toggleSlide').not('.open') ) {
                            $(lipreg).find('.toggleSlide').addClass('open');
                            $(lipreg).find('.tabla').slideDown();
                            $(lipreg).find('.info_accion > div').slideDown();
                        }
                        $(lipreg).removeClass('ok').addClass('ko');
                    }
                }
            });
        });



    } else {

        // Todas KO excepto empleados
        $(f).find('li[data-idpregunta]').removeClass('ok').addClass('ko');

        // Y ponemos ok las contestadas
        $(f).find('input[type="radio"]:checked').each(function(i) {
            $(this).closest('li[data-idpregunta]').removeClass('ko').addClass('ok');
        });
        $(f).find('input[type="text"][name^="respuesta_p"],input[type="text"][name^="proveedor_info"]').each(function(i) {
            if ( $(this).val() != '' ) {
                $(this).closest('li[data-idpregunta]').removeClass('ko').addClass('ok');
            }
        });

        if ( idForm === 'formulario-ambitos') {
            // Ahora empleados
            $('div.empleados div.item-real').removeClass('ko').removeClass('ok');
            $('div.empleados div.item-real').each(function (i) {
                var result = validaEmpleado(this);
                $(this).addClass( result.error ? 'ko' : 'ok' );
            });

            if ( $('#num_empleados').val() > 0 && $('div.empleados div.item-real.ko').length === 0 ) {
                $('li[data-idpregunta="empleados"]').removeClass('ko').addClass('ok');
            } else {
                $('li[data-idpregunta="empleados"]').removeClass('ok').addClass('ko');
            }
        }
    }
}

function validaEmpleado(obj){
    var campos_obligatorios = ['nombre', 'primer_apellido', 'nif'];
    var valor;
    var elemEmpleado = $(obj);
    for (var c in campos_obligatorios) {
        valor = $(elemEmpleado).find('[name^=' + campos_obligatorios[c] + ']').val();
        if (valor == '') {
            return {'error': true, 'campo': campos_obligatorios[c], 'mensaje':  'Indica el ' + campos_obligatorios[c].replace('_', ' ') + ' del empleado.' };
        }
        if (campos_obligatorios[c] == 'nif' && !es_dni_o_nie(valor)) {
            return {'error': true, 'campo': campos_obligatorios[c], 'mensaje':  'El NIF/NIE/DNI "' + valor + '" no es válido.' };
        }
    }
    return {'error': false};
}

function addEmpleado() {

    var inputsNoVacios = 0;
    $('div.empleados div.item-real:last-child input[type="text"]').each(function(i) {
        if ($(this).val() != '') { inputsNoVacios++; }
    });

    if ( $('div.empleados div.item-real:last-child').length && inputsNoVacios === 0 ) {
        ponMensaje({'mensaje':'Por favor, rellena primero los campos vacíos del empleado existente.'});
        return;
    }

    var $clon = $("div.empleados div.item-plantilla").clone(true);

    // Dejamos los campos vacios
    $clon.find('input[type="text"]').val('');
    $clon.find('input,select,textarea').removeAttr('data-nosend');
    $clon.find('[type="hidden"]').val(0);
    $clon.removeClass('item-plantilla').addClass('item-real').removeClass('oculto');
    $clon.appendTo($("div.empleados"));

    $("#num_empleados").val($("div.empleados div.item-real").length);
}

function addProveedor() {

    if ( $('li.proveedor-info').last().find('input[type="text"]').val() == '' ) {
        ponMensaje({'mensaje':'Por favor, rellena primero los campos vacíos del proveedor existente.'});
        return;
    }

    var $clon1 = $("li.proveedor-info").last().clone(true);
    var $clon2 = $("li.proveedor-clausula").last().clone(true);

    var numProv = Number($clon1.attr('data-numproveedor'))+1;
    $clon1.removeClass('ok').removeClass('ko');
    $clon1.attr('data-idpregunta','proveedor'+numProv);
    $clon1.attr('data-numproveedor',numProv);
    $clon1.find('input[type="text"]').attr('name','proveedor_info'+numProv).val('');
    $clon1.find('[type="hidden"][name^=num_proveedor]').val(numProv);
    $clon1.find('[type="hidden"][name^=id_proveedor]').attr('name','id_proveedor'+numProv).val(0);
    $clon1.find('span.numeroProv').text(numProv);

    $clon2.removeClass('ok').removeClass('ko');
    $clon2.attr('data-idpregunta','proveedor'+numProv+'c');
    $clon2.attr('data-numproveedor',numProv);
    $clon2.find('input[type="radio"]:checked').prop('checked',false).removeAttr('cheched');
    $clon2.find('input[type="radio"]').attr('name','proveedor_clausula'+numProv);
    $clon2.find('span.numeroProv').text(numProv);

    $clon1.insertBefore($("li.boton-anadir-prov"));
    $clon2.insertBefore($("li.boton-anadir-prov"));
}

function comprobar() {
    cerrarMensaje();

    if ( $('#formularios form:first-child').attr('id') === 'formulario-ambitos' ) {
        // Nos posicionamos en la primera página
       $('[data-idpagina]').addClass('oculto');
       $('[data-idpagina="1"]').removeClass('oculto');
        $('#titulo-pagina > h2').html($('[data-idpagina="1"]').attr('data-idpagina') + '. ' + $('[data-idpagina="1"]').children('span.txt-titulo-ambito').html());
        // mostramos botón de siguiente
        $('#formulario-ambitos input.btn.siguiente').removeClass('oculto');
        $('#formulario-ambitos input.btn.siguiente').val('Guardar y continuar');
        $('#formulario-ambitos input.btn.anterior').addClass('oculto');
    }

    gototop(500);
}
