$( document ).ready(function() {

    var f = $('#form-contacto');

    $(f).find('input[type="button"]').on('click',function(e){

        // Ocultamos mensajes de error
        $('#titulo-pagina .error').addClass('oculto');

        // Y quitamos error de los campos de form
        $(f).find('.error').removeClass('error');

        $(f).find('[data-requerido="requerido"],[data-tipo="email"],[data-tipo="telf"],[data-igual],[data-tipo="dni"]').each(function(index) {

            // No lo comprobamos si está oculto
            var valor = $(this).val();

            if ( $(this).attr('data-requerido') == 'requerido' ) {

                switch( $(this).attr('data-tipo') ) {
                    case 'radio':
                        if( $('[name='+ $(this).attr('name') + ']:checked').length == 0 ) {
                            marcaCampoError(this);
                        }
                        break;
                    default:
                        if( valor == ""){
                            marcaCampoError(this);
                        }
                        break;
                }
            } else {
                if (valor == '') return; // Si no está especificado el valor, no lo comprobamos (si no se marca como requerido, solo se comprobará el formato si hay valor)
            }

            if ( valor != '' ) {
                if ($(this).attr('data-tipo') == 'email') {
                    if (!checkEmail(valor)) {
                        marcaCampoError(this);
                    }
                }

                if ($(this).attr('data-tipo') == 'telf') {
                    var primerNum = parseInt(valor.substring(0, 1));
                    if (valor.length !== 9 || isNaN(valor) || !(primerNum === 9 || primerNum === 8 || primerNum === 7 || primerNum === 6)) {
                        marcaCampoError(this);
                    }
                }

                if ($(this).attr('data-tipo') == 'dni' && valor !== '') {
                    valor = valor.replace("-", "");
                    valor = valor.replace(" ", "");
                    valor = valor.toUpperCase();
                    if (!valida_cif(valor)) {
                        marcaCampoError(this);
                    }
                }

                var idCampoComparar = $(this).attr('data-igual');
                if ($(f).find('[name="' + idCampoComparar + '"]').length > 0) {
                    if (valor != $(f).find('[name="' + idCampoComparar + '"]').val()) {
                        marcaCampoError(this);
                    }
                }
            }
        });

        if ( $(f).find('.error').length > 0 ) {
            muestraMsgError();
        } else {

            // lanzamos petición para crear usuario en bbdd
            var ajax_data = $(f).serialize();
            $.ajax({
                url: DIR_APP + 'ajax/put/contacto',
                data: ajax_data,
                type: 'post',
                dataType: 'json',
                success: function(json) {
                    if ( json.cres === 0 ) {
                        $('#contacto .formulario, #titulo-pagina').addClass('oculto');
                        $('#gracias').removeClass('oculto');
                        gototop(500);
                    } else if ( json.cres == -10 ) {
                        muestraMsgError();
                    } else {
                        muestraMsgError('error_server');
                        console.log('No se ha podido guardar el contacto. Error = ' + json.cres);
                    }
                },
                error:function (xhr, ajaxOptions, thrownError) {
                    muestraMsgError('error_server');
                    console.log('No se ha podido guardar el contacto. Error AJAX');
                }
            });
        }
    });

    $('#form-contacto select[name="motivo"]').on('change',function(e){
        var txtarea = $('textarea[name="texto_consulta"]');
        if ( $(this).val() === 'Otros' || $(this).val() === 'Echo de menos un tratamiento de datos') {
            $(txtarea).attr('data-requerido', 'requerido');
            $(txtarea).attr('placeholder', 'Describe brevemente el motivo de tu contacto...');
        } else {
            $(txtarea).attr('data-requerido', '');
            $(txtarea).attr('placeholder', 'Si lo deseas, describe brevemente el motivo de tu contacto...');
        }
    });

    $('#form-contacto select[name="motivo"]').trigger('change');
});